<template>
  <v-dialog v-model="newDialog" persistent max-width="400">
    <v-card>
      <v-card-title>New {{ name }} permission</v-card-title>
      <v-card-text>
        <v-select
          :items="permissionlist"
          item-value="id"
          item-text="name"
          v-model="view.permissions_id"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Restful from "@/services/RestFul";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  export default {
    mixins: [validationMixin],
    props: {
      newDialog: Boolean,
      name: { type: String, default: null },
      roleId: { type: Number, default: 0 },
    },
    data() {
      return {
        loading: false,
        responseMessage: null,
        permissionlist: [],
        view: {
          roles_id: parseInt(this.roleId),
          permissions_id: null,
        },
      };
    },
    validations: {
      view: {
        permissions_id: {
          required,
        },
      },
    },
    created() {
      this.permissions();
    },
    methods: {
      permissions() {
        let self = this;
        Restful.access.permits
          .list()
          .then((response) => {
            console.log(response.data);
            this.permissionlist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
      },
      closeDialog() {
        let payload = { id: this.myId, state: false };
        this.$emit("closeNew", payload);
      },
      submit() {
        let payload = { state: false, data: this.view };
        console.log("payload:", payload);
        this.$emit("submitNew", payload);
      },
    },
  };
</script>
